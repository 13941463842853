<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmInterestFormData && Object.keys(vmInterestFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_name">
                    {{cvInterestNameLabel}}</label>
                  <input
                    v-model="vmInterestFormData.interest_name"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_category_id">
                    {{cvInterestCategoryIdLabel}}</label>
                  <input
                    v-model="vmInterestFormData.interest_category_id"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary" @click="interestEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Interests } from "../../../FackApi/api/interest"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propInterestObj: {
      type: Object,
      default: function () {
        return {
          "interest_name": "",
          "interest_category_id": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "interest_edit",
      cvCardTitle: "Edit interest",
      cvCardSubHeader: "Edit interest",
      cvSubmitBtn: "Edit",
      cvInterestNameLabel: "interest name",
      cvInterestCategoryIdLabel: "interest category id",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Interest",
      vmInterestFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.interestView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmInterestFormData) {
          if (!this.vmInterestFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * interestView
     */
    async interestView () {
      try {
        if (this.propOpenedInModal) {
          this.vmInterestFormData = this.propInterestObj
        }
        else {
          let interestId = this.$route.params.interest_id
          let interestViewResp = await Interests.interestView(this, interestId)
          if (interestViewResp && interestViewResp.resp_status) {
            this.vmInterestFormData = interestViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at interestView() and Exception:", err.message)
      }
    },
    /**
     * interestEdit
     */
    async interestEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        let interestAddResp = await Interests.interestEdit(this, this.vmInterestFormData)
        await ApiResponse.responseMessageDisplay(this, interestAddResp)
        if (interestAddResp && !interestAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseInterestEditModal", this.vmInterestFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at interestEdit() and Exception:", err.message)
      }
    }
  }
}
</script>
